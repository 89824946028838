<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: signIn.vue
Description: This file contains vue component which has the form used for signing in user, superadmin, account manager and dealers into the pondlogs
-->
<template>
  <el-row type="flex" class="signin-card">
    <el-col :span="14">
      <dot-container>
        <template slot="header">
          <p>{{ $t("dont_have_an_account") }}</p>
        </template>
        <template slot="main">
          <p>{{ $t("eruvaka_company_description_culture") }}</p>
        </template>
        <template slot="footer">
          <er-button type="text" disabled>{{ $t("Usrs_sign_up") }}</er-button>
          <!-- <router-link to="/sign-up">
          </router-link> -->
        </template>
      </dot-container>
    </el-col>
    <el-col :span="10">
      <card>
        <template slot="title">
          <h2>{{ $t("Harvest_log_in") }}</h2>
        </template>
        <template slot="subtitle">
          <p class="header">{{ $t("welcome_back_log_in") }}</p>
          <br />
          <p class="title">{{ $t("login_to_access_your_account") }}</p>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            type="error"
            style="margin-bottom: 10px"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <el-alert
            v-if="error403"
            type="error"
            @close="handle403AlertClose"
            style="margin-bottom: 10px"
          >
            <i18n :path="errorMsg" tag="span">
              <template v-slot:action>
                <a href="/resend-email">{{ $t("resend_email") }}</a>
              </template>
            </i18n>
          </el-alert>
          <el-alert
            v-if="errorDealerLogin"
            type="error"
            @close="handleDealerLoginError"
            style="margin-bottom: 10px"
          >
            <i18n path="please_login_using_action" tag="span">
              <template v-slot:action>
                <a href="https://pondlogs.com" target="_blank">
                  {{ $t("pondlogs-v1") }}
                </a>
              </template>
            </i18n>
          </el-alert>
          <div class="locale-changer"></div>
          <er-form
            :model="user"
            v-loading="loading"
            element-loading-background="white"
            size="small"
            :ref="formName"
          >
            <div class="user-properties-element">
              <er-form-item
                prop="email"
                :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
              >
                <er-input
                  v-model="user.email"
                  :placeholder="$t('Usrs_enter_email_addr')"
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      email
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <er-form-item
                prop="password"
                :error="
                  aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
                "
              >
                <er-input
                  v-model="user.password"
                  :placeholder="$t('Usrs_pwd')"
                  show-password
                  ref="password"
                  @keyup.native.enter="submitSignInDetails"
                >
                  <!--  -->
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <el-row type="flex" justify="end">
                <router-link to="/forgot-password">
                  <er-button type="text" class="btn_forgot-password">{{
                    $t("Comn_forgot_ur_pwd")
                  }}</er-button>
                </router-link>
              </el-row>
              <el-row class="btn-container">
                <er-button
                  class="btn_submit"
                  size="small"
                  @click="submitSignInDetails"
                >
                  {{ $t("Harvest_log_in") }}
                  <span class="material-icons-round icon__suffix--space">
                    login
                  </span>
                </er-button>
                <br />
                <el-divider direction="horizontal">{{ $t("or") }}</el-divider>
                <google-sign-in></google-sign-in>
              </el-row>
            </div>
          </er-form>
        </template>
      </card>
    </el-col>
    <div class="company_info_sign">
      <p style="display: flex; margin: 0px 0px 10px 25px">
        <span style="color: #ffffff; font-weight: 600">
          {{ $t("eruvaka-technologies", { year: new Date().getFullYear() }) }}
        </span>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/privacy"
          target="_blank"
          >{{ $t("privacy-policy") }}
        </a>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/terms"
          target="_blank"
          >{{ $t("terms-and-conditions") }}
        </a>
      </p>
    </div>
  </el-row>
</template>
<script>
import User from "@/model/user.js";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import card from "./card";
import googleSignIn from "./googleSignIn";
import dotContainer from "./dotContainer";
import localStore from "@/utils/localStore";
import { userTypes } from "@/middleware/pageAccessManager";
import { mapActions } from "vuex";
import {
  erButton,
  erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";

export default {
  mixins: [errorHandlerMixin, redirectsMixin, authErrorKeyMapMixin],
  components: {
    erButton,
    erForm,
    erInput,
    erFormItem,
    card,
    googleSignIn,
    dotContainer
  },
  data: function () {
    return {
      user: new User(),
      ehm__errMessagesObject: new User(),
      loading: false,
      formName: "signInForm",
      error403: false,
      errorMsg: '',
      errorDealerLogin: false
    };
  },
  mounted() {
    const appTitle = document.getElementById("appTitle");
    appTitle.innerHTML = `${this.$t("Harvest_log_in")} | ${this.$t(
      "PondLogs"
    )}`;
    const username = this.$route.query.username;
    if (username) {
      this.user.email = username;
      // this.$nextTick(() => {
      //   this.$refs.password.elInput.focus();
      // });
    }
  },
  methods: {
    ...mapActions("auth", {
      signIn: "signIn",
      dealerSignOut: "dealerSignOut"
    }),
    handle403AlertClose: function () {
      this.error403 = false;
    },
    handleDealerLoginError: function () {
      this.errorDealerLogin = false;
    },
    ehm__error403Handler: function (err, isAuthenticated) {
      console.log(err.response);
      if (err.response.data.error_code === "INACTIVE_USER") {
        this.errorMsg = 'inactive_user';
      } else {
        this.errorMsg = 'email_not_verified_with_resend_link';
      }
      this.error403 = true;
    },
    ehm__error401Handler: function (err, isAuthenticated) {
      console.log(err);
      this.ehm__unhandledErrorMessage = "unauthenticated_error_message";
    },
    async submitSignInDetails() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_loading_pls_wait")
      });
      try {
        this.ehm__unhandledErrorMessageV2 = null;
        this.errorDealerLogin = false;
        this.ehm__errMessagesObject = new User();
        await this.signIn(this.user);
        const userType = localStore.getItem("curr-user-type");
        // console.log(localStore.getItem("show-welcome-dialog"));
        if (localStore.getItem("show-welcome-dialog") === null) {
          localStore.setItem("show-welcome-dialog", true);
        }
        const unSetLoading = (
          messageTitle = "Usrs_success_msg",
          message = "User_sigin_success",
          type = "success"
        ) => {
          return (from, to, next) => {
            if ( type === 'User-error') {
              loading.close();
            } else {
              loading.close();
              this.loading = false;
              this.$notify({
                title: this.$t(messageTitle),
                message: this.$t(message),
                duration: 5000,
                type
              });
            }
          };
        };
        if (userType === userTypes.super_admin) {
          this.$router.push(
            "/admin/",
            unSetLoading(),
            unSetLoading("failed", "Something_went_wrong", "error")
          );
        } else if (userType === userTypes.dealer) {
          // await this.dealerSignOut();
          // loading.close();
          // this.loading = false;
          // this.errorDealerLogin = true;
          this.$router.push("sign-in");
          this.$router.push(
            "/dealer/",
            unSetLoading(),
            unSetLoading("failed", "Something_went_wrong", "error")
          );
        } else if (userType === userTypes.account_manager) {
          this.$router.push(
            "/account-manager/",
            unSetLoading(),
            unSetLoading("failed", "Something_went_wrong", "error")
          );
        } else if (userType === userTypes.skretting_technician) {
          this.$router.push(
            "/skretting-technician/",
            unSetLoading(),
            unSetLoading("failed", "Something_went_wrong", "error")
          );
        } else {
          this.$router.push(
            "/user/",
            unSetLoading(),
            unSetLoading("failed", "Something_went_wrong", "User-error")
          );
        }
      } catch (err) {
        this.ehm__errorMessages(err, false);
        loading.close();
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/mixins";
.signin-card {
  grid-column-start: 3 !important;
  grid-column-end: 6 !important;
  height: 100%;
  .el-alert .el-alert__description {
    margin: 0px;
  }
  .el-alert__closebtn {
    top: 10px;
  }
  .card {
    height: 100%;
  }
  .card .card-subtitle {
    text-align: center;
    line-height: 0.8;
    margin-top: 15px;
    .header {
      margin-bottom: 2px;
      @include responsiveProperty(font-size, 1.1em, 1.1em, 1em);
    }
    .title {
      @include responsiveProperty(font-size, 0.9em, 0.8em, 0.8em);
    }
  }
  .el-card__body {
    padding: 0rem 5em 2em 4em;
  }
  .btn_forgot-password {
    padding: 0px 0px 15px 0px;
  }
  .btn-container {
    line-height: 2;
    padding: 40px 10px 20px;
  }
  .company_info_sign {
    position: fixed;
    @include responsiveProperty(bottom, 2px, 4px, 6px);
    @include responsiveProperty(left, 2px, 4px, 6px);
  }
}
</style>
